/* .container-bg {
    background-color: black;
    background-image: url(http://localhost:3000/static/media/grey-bg.eaa1cd5ed62bc30ba345.png);
    background-repeat: no-repeat;
} */
/* .login_bg {
    background-color: black !important;
} */

.loginForm {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    /* padding: 25px; */
}

p.already-acc {
    font-family: Arial !important;
}

.login_box small.errMsg {
    top: 0px;
}

/* .p-5 {
    padding: 3rem !important;
} */

.login_top_right {
    display: flex;
    justify-content: space-between;
}

.new_tot {
    display: flex;
    align-items: center;
}

.new_tot p {
    color: #ffffff;
    margin-left: 10px;
    cursor: pointer;
}

.login_top {
    margin-left: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_top p {
    color: #ffffff;
    margin-left: 10px;
    cursor: pointer;
}

.reg_top p {
    background-color: #ffffff;
    padding: 10px 20px;
    border: 1px solid;
    border-radius: 6px;
}

.export {
    display: flex;
    align-items: center;
}

.export_arrow {
    width: auto;
}

.export_btn {
    background: #ffffff;
    border: 1px solid #f7f7f7;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    align-items: center;
    padding: 9px 20px;
    gap: 10px;
    display: flex;
    cursor: pointer;
    margin-left: 100px;
}

.login_content {
    text-align: center;
}

.login_box {
    text-align: center;
    border: 1px solid #FFF100;
    margin-right: auto;
    margin-left: auto;
    width: 400px;
    background-color: #000000;
    border-radius: 6px;
}

.login_content h1 {
    color: #fff;
    font-family: Arial;
    font-size: 27px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;

}

.login_content p {
    color: #fff;
    font-family: Arial;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
}

.login_box p {
    font-family: Arial;
    font-size: 23px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    margin: 25px 0px 25px 0px;
}

.password_icons input {
    padding: 13px 30px 10px 30px;
    border: 1px solid #C8CCD0;
    border-radius: 8px;
    width: 80%;
    outline: none;
}

.password_icons img {
    position: absolute;
    top: 50%;
    transform: translate(10px, -50%);
}

/* .password_icons img {
    position: absolute;
    width: auto;
    top: 50%;
    transform: translate(10px, -50%);
} */

.password_icons {
    margin-top: 20px;
    position: relative;
}

.bot-bar {
    /* position: absolute; */
    bottom: 20px;
    left: 0;
    right: 0;
}

.check_forget {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 10px 40px 10px;
}

.input_text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input_text p {
    font-family: Arial;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #DEDEDE;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 10px;
}

.login_forget a {
    font-family: Arial;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #E2E2E2 !important;
    cursor: pointer;
}

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.login_arrow {
    width: auto;
}

.password_icons .errMsg {
    color: red;
}

p.newuser a {
    color: #fff100;
    text-decoration: none;
    font-family: Arial;
}

p.newuser {
    color: #fff;
    font-family: Arial;
}

.login_btn {
    background: #FFF100;
    border: 1px solid #FFF100;
    border-radius: 8px;
    font-family: Arial;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    align-items: center;
    padding: 9px 20px;
    gap: 10px;
    display: flex;
    cursor: pointer;
    text-decoration: none;
}

.bottom_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 0;
    /* margin-top: 80px; */
}

/* .bottom_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 100px 0;
    margin-top: 80px;
    position: absolute;
    width: 100%;
    bottom: 0;
} */
.bottom_bar_left p {
    color: #fff;
    font-family: Arial;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

}

.bottom_privacy p {
    font-family: Arial;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: right;
    color: #FFFFFF;
    cursor: pointer;
}

.bottom_bar_right {
    color: #fff;
    display: flex;
}

.trmsndcond {
    margin-left: 30px;
}

.trmsndcond p {
    font-family: Arial;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: right;
    color: #FFFFFF;
    cursor: pointer;
}

.header nav ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header nav ul li a {
    font-family: Arial;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
    text-decoration: none;
    letter-spacing: 0em;
    text-align: left;
}

li.register-btn a {
    color: #000 !important;
    background-color: #fff;
    font-family: Arial;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

form.form-area input {
    border-radius: 4px;
    margin: 4px 0px;
}

li.register-btn a {
    padding: 10px 20px;
    border-radius: 4px;
}

img.logo {
    height: 30px !important;
    width: auto;
}

.header nav ul li {
    padding: 0px 10px;
    margin: 0px 5px;
}

button.btn.register-btn {
    background-color: #FFF100;
    margin-top: 20px;
}


.d-flex {
    display: flex !important;
    align-items: center;
}

.justify-content-between {
    justify-content: space-between !important;
}

.toaster h2 {
    padding: 10px;
    font-size: 16px;
    margin: 0;
    color: #fff;
}

.toaster {
    position: fixed;
    right: 10px;
    z-index: 999;
    border-radius: 5px;
}

.toaster.suc {
    background-color: green;
}

.toaster.fail {
    background-color: red;
}

.pass-wrapper {
    position: relative;
    /* display: flex;
    margin-bottom: 14px; */
}

.pass-wrapper .eeyeee {
    position: absolute;
    top: 63%;
    right: 14%;
    transform: translate(-14%, -63%);
    width: 25px;
}

.pass-wrapper .eeyeee:hover {
    /* color: #00fcb6; */
    cursor: pointer;
}

.login_msg {
    margin: 10px 35px;
    font-weight: 600;
}

.toster_Msg.fail {
    color: red;
    font-size: 16px !important;
}

.toster_Msg.suc {
    color: green;
    font-size: 16px !important;
}

.check_forget a {
    text-decoration: none;
    margin: 0;
    color: #ffff;
    font-family: Arial;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.42px;
    text-align: left;

}

@media (max-width: 767px) {

    button.btn.login_btn.btn-primary {
        width: 100% !important;
    }

    button.login_btn {
        width: 80%;
        display: flex;
        justify-content: center;
    }

    .loginForm {
        display: flex;
        align-items: center;
        /* padding: 25px; */

    }

    .login_box {
        text-align: center;
        border: 1px solid #FFF100;
        margin-right: auto;
        margin-left: auto;
        width: auto;
        left: 30%;
    }

    .bottom_bar {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        /* bottom: 0; */
        position: absolute;
        right: 80px;
    }

    .login_footer .bottom_bar {
        bottom: -150px;
    }

    .bottom_bar_right {
        color: #fff;
        justify-content: center;
        display: flex;
        gap: 20px;
    }

    .bottom_bar_left p {
        font-size: 13px;
    }

    .header nav ul {
        padding: 0px !important;
    }

    .header nav ul li {
        padding: 0px 5px;
    }

    .header nav ul li a {
        font-size: 11px;
    }

    nav.p-2 {
        padding: 0.5rem 0px !important;
        margin-top: 30px;
    }

    img.logo {
        width: 170px;
        margin: auto;
        height: auto !important;
    }

    .bottom_bar_left p {
        text-align: center;
    }

    .login_box div#mob-user-tutor {
        padding: 0px 0px 30px 0px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .header {
        flex-direction: column;
    }

    .login_content p {
        padding: 0px 10px;
    }


    div#mob-user-tutor a {
        color: #FFF100 !important;
        font-weight: 600;
        font-family: 'Arial';
        cursor: pointer;
    }
}

@media (max-width:576px) {

    div#mob-user-tutor {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;
    }

    div#mob-user-tutor a {
        color: #FFF100 !important;
        font-weight: 600;
        font-family: 'Arial';
        cursor: pointer;
    }

    .login_footer .bottom_bar {
        bottom: -150px;
    }
<<<<<<< HEAD
    
=======

>>>>>>> staging
    div#mob-user-tutor a {
        text-decoration: none;
    }
}