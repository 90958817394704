.editClaimtotal {
    display: flex;
}

.all_asds {
    background-color: #fff;
}

.known-optin[data-val='partnochecked'] input[type='radio'] {
    position: relative;
}

.known-optin[data-val='partnochecked'] input[type='radio']::before {
    position: absolute;
    content: '';
    width: 7.5px;
    height: 7.5px;
    top: 2.69px;
    left: 2.42px;
    border-radius: 50%;
    background-color: blue;
}

.known-optin.partdesc[data-val='partnochecked'] input[type='radio']::before {
    content: unset;
}

.known-optin[data-val='partdescchecked'] input[type='radio'] {
    position: relative;
}

.known-optin.partno[data-val='partdescchecked'] input[type='radio']::before {
    content: unset;
}

.known-optin.partdesc[data-val='partdescchecked'] input[type='radio']::before {
    position: absolute;
    content: '';
    width: 7.5px;
    height: 7.5px;
    top: 2.69px;
    left: 2.42px;
    border-radius: 50%;
    background-color: blue;
}

/* .known-optin[data-val='partnochecked'] input[type='radio'] {
    position: relative;
}
.known-optin[data-val='partnochecked'] input[type='radio']::before {
    position: absolute;
    content: '';
    width: 7.5px;
    height: 7.5px;
    top: 2.69px;
    left: 2.42px;
    border-radius: 50%;
    background-color: blue;
} */
.remove_file {
    position: relative;
    width: 130px;
    display: flex;
    align-items: center;
}

a.LinkInvoice {
    display: flex;
    width: 130px;
    background-color: #000;
    padding: 5px 15px;
    text-decoration: none;
    align-items: center;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
}

span.removeInvoice:hover {
    background-color: #fff;
    color: #000;
}

span.removeInvoice {
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    align-items: center;
    font-weight: 600;
    width: 20px;
    text-decoration: none;
    border-radius: 50%;
    text-align: center;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 6px;
}

.CanPop {
    position: fixed;
    top: 0;
    height: 100%;
    right: 0;
    width: 100%;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.32);
}

.CanPop .can_popTotal {
    position: absolute;
    background: #fff;
    top: 50%;
    left: 50%;
    width: 38%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
}

.cancel_content p {
    color: #000000;
    padding: 20px;
    margin-bottom: 0 !important;
    text-align: center;
}

.can_title {
    color: #000000 !important;
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.46px;
    text-align: center;
    padding: 20px;
}

.can_popTotal hr {
    margin: 0px !important;
}

.cancel_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    gap: 20px;
}

.cancel_btns .can_yes {
    padding: 5px 20px;
    border: 1px solid #000;
    background-color: #000;
    color: yellow;
    font-weight: 600;
    padding: 7px 25px;
    font-size: 14px;
}

.cancel_btns .can_no {
    padding: 5px 15px;
}

.invo_file {
    display: flex;
    align-items: baseline;
    /* justify-content: space-between; */
}
.invo_file_rma{
    display: flex;
    align-items: center;
}
.file_input input {
    width: 96%;
}

li.clmerrmsg {
    padding: 10px 0px 0 0;
    /* width: 170px; */
    text-align: start;
    margin-right: auto;
    margin-left: auto;
}

.cancel_content ul {
    margin-bottom: 0;
}

@media (max-width:576px) {

    .known-optin.partdesc[data-val=partdescchecked] input[type=radio]:before,
    .known-optin[data-val=partnochecked] input[type=radio]:before {
        left: 4.52px;
        top: 3.75px;
    }

    .known-optin[data-val=partnochecked] input[type=radio]:before {
        height: 8.5px;
    }

    .known-optin[data-val=partdescchecked] input[type=radio]:before {
        height: 8.5px;
    }

    input:is[type="date"] {
        text-align: left;
    }

    .lable_cs input {
        text-align: left;
    }

    .remove_file {
        width: 100px;
    }

    /* .invo_file {
        flex-direction: column;
    } */
}