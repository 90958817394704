.returns_table {
    background-color: #fff;
    border-radius: 10px;

}

.details_All {
    background-color: #f1f1f1;
}

.menuuuu {
    display: flex !important;

}

.card_top .col-sm-6 {
    flex: 1 1 50%;
}

.sc-eTNRI.lnOUep {
    color: rgb(0, 0, 0);
    font-weight: 700;
    font-size: 14px;
}

.lnOUep {
    overflow: unset !important;
}

/* .claim_top_btns.rtn_top {
    margin-bottom: 10px;
} */

/* .view_btn_rtn {
    padding: 5px 20px;
    color: #007FFF;
    border: 1px solid #007FFF;
    width: 75px;
    background-color: #E4F2FF;
} */

button.view_btn_rtn {
    color: #007FFF;
    padding: 5px 15px;
    border: 1px solid #007FFF;
    background-color: #E4F2FF;
    font-family: Arial;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.08px;
    text-align: center;
}

.returns_table tr {
    text-align: center;
}

.rtn_pages select.form-select {
    width: 15%;
}

img.rtn_edit {
    width: 20px;
    height: 20px;
}

.claims_card .container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 0 !important;

}

.card.multicard.retdetails {
    margin-bottom: 4rem;
}


.view_btn_adm {
    color: #007FFF;
    padding: 5px 15px;
    border: 1px solid #007FFF;
    background-color: #E4F2FF;
    font-family: Arial;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.08px;
    text-align: center;
}

.rtndelimg {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 5px;
}

.subName.prodoinfo {
    margin-top: 20px;
}

.rma_sub_color {
    color: #3D9C57;
    margin-bottom: 0px;
    position: relative;
}

.rma_sub_color:hover .tooltiptext-closed {
    visibility: visible;
    background-color: #fff;
    color: #000000;
    border: 2px solid #3D9C57;
    padding: 10px;
}

.rma_sub_color .tooltiptext-closed {
    visibility: hidden;
    position: relative;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 44px 0;
    position: absolute;
    right: 320px;
    z-index: 1;
    width: 300px;
    font-size: 16px;
    bottom: -40px;
    font-family: 'Arial';
}

.dec_color {
    color: rgb(15 15 15);
    margin-bottom: 0px;
    position: relative;
}

.dec_color:hover .tooltiptext-closed {
    visibility: visible;
    background-color: #fff;
    color: #000000;
    border: 2px solid rgb(15 15 15);
    padding: 10px;
}

.dec_color .tooltiptext-closed {
    visibility: hidden;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 44px 0;
    position: absolute;
    right: 320px;
    z-index: 1;
    width: 300px;
    font-size: 16px;
    bottom: -62px;
    font-family: 'Arial';
}

.admin_rma_sub_color {
    color: #3D9C57;
    margin-bottom: 0px;
    position: relative;
}

.admin_rma_sub_color:hover .tooltiptext-closed {
    visibility: visible;
    background-color: #fff;
    color: #000000;
    border: 2px solid #3D9C57;
    padding: 10px;
}

.admin_rma_sub_color .tooltiptext-closed {
    visibility: hidden;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 44px 0;
    position: absolute;
    right: 320px;
    z-index: 1;
    width: 300px;
    font-size: 16px;
    bottom: -40px;
    font-family: 'Arial';
}

.admin_dec_color {
    color: rgb(15 15 15);
    margin-bottom: 0px;
    position: relative;
}

.admin_dec_color:hover .tooltiptext-closed {
    visibility: visible;
    background-color: #fff;
    color: #000000;
    border: 2px solid rgb(15 15 15);
    padding: 10px;
}

.admin_dec_color .tooltiptext-closed {
    visibility: hidden;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 44px 0;
    position: absolute;
    right: 320px;
    z-index: 1;
    width: 300px;
    font-size: 16px;
    bottom: -62px;
    font-family: 'Arial';
}

.admin_wait_color {
    color: red;
    margin-bottom: 0px;
    position: relative;
}

.admin_wait_color:hover .tooltiptext-closed {
    visibility: visible;
    background-color: #fff;
    color: #000000;
    border: 2px solid red;
    padding: 10px;
}

.admin_wait_color .tooltiptext-closed {
    visibility: hidden;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 44px 0;
    position: absolute;
    right: 320px;
    z-index: 1;
    width: 300px;
    font-size: 16px;
    bottom: -62px;
    font-family: 'Arial';
}

.rtntable {
    margin-bottom: 50px;
}
.asdasdasdas{
    margin-bottom: 0px;
}
@media (max-width:767px) {
    .claim_top_btns.rtn_top {
        margin-bottom: 10px;
        /* flex-direction: column; */
        /* align-items: flex-start; */
        /* margin-left: 14px !important; */
        gap: 0px;
    }

    .returns_table {
        border-radius: 0px;
    }

    .rtn_view span {
        background-color: #E4F2FF;
        color: #007FFF;
        padding: 7px;
        border: 1px solid #007FFF;
        font-family: Arial;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.08px;
        text-align: center;

    }

    .menuuuu {
        flex-direction: column;
    }
}

@media (max-width:576px) {
    .menuuuu {
        flex-direction: column;
    }

}