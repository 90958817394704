/* .profile_total {
    background-color: #f1f1f1;
} */
.profile_total {
    display: flex;
    background-color: #f1f1f1;
}

.create_passsword {
    margin-top: 20px;
    position: relative;
}

.create_passsword input {
    padding: 12px 20px 12px 30px;
    border: 1px solid #C8CCD0;
    border-radius: 8px;
    width: 80%;
    outline: none;
}

.create_passsword img {
    position: absolute;
    width: auto;
    top: -4px;
    transform: translate(10px, 25px) !important;
}

.prof_card_total.pt-5 {
    display: block;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.prof_card_total.pt-5 .card-body {
    padding: 45px 0px 0px 60px;
}

.prof_data label {
    font-family: Arial;
    font-size: 17px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
    color: #000;
}

.prof_data p {
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-weight: 300;
    line-height: 19.2px;
    text-align: left;
    margin-bottom: 0;
    margin-top: 10px;
}

.prof_card_total .row {
    margin-bottom: 30px;
}

.filter_popTotal {
    position: relative;
}

.deacTotal {
    position: fixed;
    top: 0;
    height: 100%;
    right: 0;
    width: 100%;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.32);
}

.deacTotal .deactivateContent {
    position: absolute;
    background: #fff;
    top: 50%;
    left: 50%;
    width: 38%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
}

.changePass_btns {
    display: flex;
    gap: 20px;
    padding: 30px 0 0;
    margin-top: 0 !important;
}

.btn-area .changeCan {
    color: #000;
    border: 1px solid #000;
    padding: 10px 27px;
    font-family: Arial;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.59px;
    text-align: left;
}

.btn-area .change_sub {
    background-color: #fff100;
    color: #000;
    padding: 10px 27px;
    font-family: Arial;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.59px;
    text-align: left;
    border: none;
}

.create_passsword .eeyeee {
    position: absolute;
    top: -12%;
    right: 8%;
    transform: translate(-14%, -63%);
    width: 22px;
}

.create_passsword .eeyeee:hover {
    /* color: #00fcb6; */
    cursor: pointer;
}

.prof_toaster h2 {
    padding: 10px;
    font-size: 16px;
    margin: 0;
    color: #fff;
}

.prof_toaster {
    position: fixed;
    right: 10px;
    z-index: 999;
    border-radius: 5px;
}

.prof_toaster {
    position: fixed;
    right: 10px;
    width: 20%;
}

.prof_toaster.suc {
    background-color: green;
}

.prof_data button {
    color: #000;
    cursor: pointer;
    background-color: #FFF100;
    padding: 6px 20px;
    font-family: Arial;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.54px;
    text-align: left;
    border: none;
}

.prof_data p {
    word-break: break-all;

}

.dfsfsdfsfsf input {
    border: 1px solid;
}

/* .dummy {
    position: absolute;

}

.dummy input {
    padding: 10px 30px 10px 30px;
    border: 1px solid #C8CCD0;
    border-radius: 8px;
    width: 80%;
    outline: none;
}

.dummy img {
    position: absolute;
    transform: translate(10px ,15px);
} */

@media (max-width: 767px) {
    .prof_card_total {
        width: 100%;
    }

    .deacTotal .deactivateContent {
        width: 80%;
    }

    .deactivateContent .cngpass {
        margin-top: 0px !important;
        width: 80%;
        margin-right: auto;
        margin-left: auto;
    }

    .deacTotal .deactivateContent {
        background: none;
    }

    .prof_card_total .row {
        margin-bottom: 10px;
    }

    .col.prof_data {
        margin-bottom: 10px;
    }

    .profile_total {
        flex-direction: column;
    }

    .prof_card_total.pt-5 {
        margin-bottom: 100px;
    }
}


@media (max-width:576px) {

    .deactivateContent .cngpass {
        width: 109%;
    }

    .prof_toaster {
        width: 61%;
    }

    .prof_card_total.pt-5 .card-body {
        padding: 25px;
    }

    .prof_card_total .row {
        margin-bottom: 10px;
    }

    .col.prof_data {
        margin-bottom: 10px;
    }

    .profile_total {
        flex-direction: column;
    }

    .prof_card_total.pt-5 {
        margin-bottom: 100px;
    }

}