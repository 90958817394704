.details_card {
    margin: 25px 0;
    width: 70%;
}

.userbtns {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
}

.userbtns .rtn_can {
    border: 1px solid;
    color: #000;
    background-color: #fff;
    padding: 5px 15px;
}

.userbtns .rtn_submit {
    border: 1px solid;
    background: #000;
    color: #fff;
    padding: 5px 15px;
}

.all_btns_user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 10px;
}

.delbtnuser {
    font-family: 'ARIAL';
    border: unset;
    background-color: #000;
    /* font-size: 18px; */
    font-weight: 500;
    border: 1px solid;
    padding: 5px 14px;
    color: #fff;
}

.user_heading span {
    font-family: 'Arial';
    font-size: 20px;
    font-weight: 500;
    line-height: 23.46px;
    text-align: left;
}

.toaster.suc.user_suc_pop {
    top: 5px;
}

.adminpass {
    padding: 0.5rem 2.5rem !important;
    background-color: #fff;
    border: unset;
}

.create_passsword.cpsadmin {
    margin-bottom: 20px;
}

.adminpasstitle {
    padding: 20px 0 10px;
}

.craft_serch.btn-area.changePass_btns.adminpassbtns {
    margin-bottom: 20px;
}

.SelectDrp select {
    width: 100%;
    padding: 10px;
    border-color: #dee2e6;
    border-radius: 5px;
    background-color: #ffff;
}

@media (max-width:576px) {
    .details_card {
        width: 100%;
    }

    .userbtns img {
        display: none;
    }

    .delbtnuser {
        padding: 5px 8px;
    }

    .all_btns_user {
        flex-direction: column-reverse;
    }

    .userbtns.rmvupt {
        margin-top: 25px;
    }

    .all_btns_user {
        padding: 0;
    }

    button.rtn_can.uppass {
        padding: 5px 5px;
    }

    form.reset.form-area.cngpass.p-5.adminpass {
        padding: 0;
        margin-top: 0;
    }
}