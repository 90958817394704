.claims_table {
    /* border: 1px solid; */
    background-color: #fff;
    border-radius: 10px;
}

.claim_top_btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    gap: 15px;
}

.claimBtns {
    gap: 15px;
    display: flex;
}

.filter_popTotal {
    position: relative;
}

.claim_top_btns .claim_btn {
    background-color: #000000;
    padding: 5px 12px;
    color: #fff;
    display: flex;
    border: none;
    gap: 10px;
    align-items: center;
    font-size: 15px;
}

.claimBtns a {
    text-decoration: none;
}

.claim_top_btns .export_btnn {
    color: #000000;
    background-color: #fff;
    border: 1px solid;
    padding: 4px 12px;
}

.control_search form {
    text-align: right;
    position: relative;
}

.claim_top_bar {
    border-bottom: 1px solid #D4DAE8;
    padding: 10px 0px;
}

.claim_top_btns .filter_btnn {
    color: #000000;
    border: 1px solid;
    background-color: #fff;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.repair_btn {
    color: #007FFF;
    border: 1px solid #007FFF;
    background-color: #E4F2FF;
}

/* table tbody tr {
    height: 60px !important; 
}

table thead tr {
     height: 60px; 
} */

table {
    border-radius: 10px;
}

.control_search form input {
    padding: 10px 40px 10px 10px;
    border: 1px solid #C8CCD0;
    border-radius: 8px;
    width: 99%;
}

.claim_top_bar h3 {
    margin: 0px;
    font-family: Arial;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.46px;
    text-align: left;
}

.control_search form img {
    position: absolute;
    width: 18px;
    transform: translate(-5px, 15px);
    right: 10px;
}

.notify-area {
    position: relative;
    width: 50px;
    cursor: pointer;
}

/* .notify-area::after {
    content: '';
    width: 10px;
    transform: rotate(45deg);
    right: 0;
    height: 10px;
    border-right: 10px solid #000;
    border-bottom: 0px solid #000;
    border-top: 10px solid #0000;
    position: absolute;
} */

.control_search {
    /* position: relative; */
    /* display: flex; */
    gap: 10px;
    align-items: center;
}

.sideMenu {
    display: flex;
    position: relative;
}

.iconSearch {
    display: flex;
}

button.claim_btn a {
    color: #ffff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}

.itemsPer {
    align-items: baseline;
    display: flex;
    gap: 30px;
}

.totalPagination nav {
    display: flex;
    align-items: baseline;
    gap: 50px;
}

.itemsPer select {
    padding: 4px 0;
    cursor: pointer;
}

a.page-link {
    color: #000;
    cursor: pointer;
}

.active>.page-link,
.page-link.active {
    background-color: #000;
    border-color: #000;
}

.claim_pagination {
    display: flex;
    align-items: baseline;
    /* gap: 20px; */
    justify-content: space-between;
}

.claim_checkbox {
    visibility: inherit !important;
}

tbody tr td a {
    color: #000 !important;
    text-decoration: none;
}

.claims_table td img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.user_topbar {
    display: flex;
    align-items: center;
}

.form {
    max-width: 330px;
    padding: 15px;
    padding-top: 120px;
    margin: 0 auto;
}

.form .form-heading {
    margin-bottom: 20px;
    text-align: center;
}

.form .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.form .form-control:focus {
    z-index: 2;
}

.form input[type="text"] {
    margin-bottom: -1px;
    border-radius: 0;
}

.details_All {
    background-color: #f1f1f1;
}

.claimPop {
    position: absolute;
    top: 0;
    right: 0px;
    width: 35%;
    bottom: 0;
    z-index: 999;
}

.claimPop .claim-popTotal {
    background: white;
    min-height: 370px;
    /* border: 1px solid #A5CFFF; */
    box-shadow: 3px 7px 27px -1px rgba(34, 28, 88, 0.6);
    border-radius: 10px 0px 0px 10px;
}

.popSubclass {
    display: flex;
    /* align-items: baseline; */
    /* height: 80vh; */
}

.filter_btns .clr_btn {
    color: #ffff;
    background-color: #000;
    padding: 3px 13px;
    border: none;
}

.claim-pop_right {
    padding: 25px 10px;
    height: 300px;
}

.claim-pop_right form {
    height: 300px;
    position: relative;
}

.ant-picker-clear {
    display: none;
}

.claim-pop_left {
    width: 25%;
    height: 370px;
    background-color: #2a2727;
    border-radius: 10px 0 0;
}

.claim-pop_left ul li {
    color: #ffff;
    padding: 35px 0 0 20px;
    font-family: Arial;
    font-size: 18px;
    font-weight: 500;
    line-height: 15.25px;
    cursor: pointer;
}

.claim-pop_left ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.filt_checkbox input {
    visibility: visible !important;
    cursor: pointer;
}

.filt_checkbox label {
    cursor: pointer;
}

.filter_btns {
    display: flex;
    justify-content: flex-end;
    /* padding: 20px 0 0; */
    gap: 20px;
    bottom: -20px;
    position: absolute;
}

.content {
    display: none;
}

.show-content {
    display: block;
    /* height: 64vh; */
}


.filt_checkbox .form-check-input[type=checkbox] {
    border: 1px solid #000;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-picker-outlined {
    width: 217px;
}

.display_text {
    display: flex;
    width: 100%;
    text-align: center;
    padding: 10px 0 0;
    color: #000;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    font-family: Arial;

}

table tbody tr {
    cursor: pointer;
}

.filter_btns .filCan {
    padding: 4px 10px;
    background-color: #fff;
    border: 1px solid #000;
}

.filt_checkbox .form-check-input[type=checkbox]:checked+label {
    font-weight: 600;
}

p.draft_color {
    color: #595959;
}

.sub_color {
    color: #3D9C57;
    margin-bottom: 0px;
    position: relative;
}

.sub_color:hover .tooltiptext-closed {
    visibility: visible;
    background-color: #fff;
    color: #000000;
    border: 2px solid #3D9C57;
    padding: 10px;
}

.sub_color .tooltiptext-closed {
    visibility: hidden;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 44px 0;
    position: absolute;
    right: 145px;
    z-index: 1;
    width: 300px;
    font-size: 16px;
    bottom: -40px;
    font-family: 'Arial';
}

p.rej_color {
    color: #f50b0b;
}

.pagination {
    --bs-pagination-padding-x: 0.5rem !important;
}

.processcheck_color {
    color: rgb(167, 0, 167);
    position: relative;
    width: 125px;

}

.processcheck_color:hover .tooltiptext-closed {
    visibility: visible;
    background-color: #fff;
    color: #000;
    border: 2px solid rgb(167, 0, 167);
    padding: 10px;
}

.processcheck_color .tooltiptext-closed {
    visibility: hidden;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 44px 0;
    position: absolute;
    right: 130px;
    z-index: 1;
    width: 300px;
    font-size: 16px;
    bottom: -40px;
    font-family: 'Arial';
}

.itemsPage p {
    font-size: 15px;
    font-family: 'Arial';
}

.mob_card {
    display: none;
}

.lmenu {
    display: block;
}

.tmenu {
    display: none;
}

.page_wid {
    display: flex;
    gap: 10px;
    align-items: baseline;
}

/* .page_wid span {
    display: none;
} */

.claim_pagination {
    padding: 0px 10px 0 10px;
}

.user_topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.act_img {
    display: flex;
    gap: 10px;
}

.del_color {
    color: red;
}

.claim_footer {
    padding: 10px 10px 0;
    color: #fff;
    background-color: #000000;
    height: 35px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
}

.clmuser span {
    cursor: pointer;
}

.claims_table tbody .srlnum {
    width: 150px;
}

.warrtylog {
    display: flex;
    align-items: flex-end;
    background-color: #fff;
    padding: 4px 13px;
    gap: 5px;
    cursor: pointer;
    border: 1px solid;
}

.warrntyp p {
    margin-bottom: 0;
    font-size: 15px;
}

.warrntyp p {
    color: #000;
}

.labour-sub {
    display: flex;
}



@media (min-width: 767px) {

    .claims_page select.form-select {
        width: 15%;
    }

    button.cancal-sub-btn {
        color: #000;
    }
}

@media (max-width: 1024px) {
    .claimPop.filres {
        width: 60%;
    }

    .claim_pagination {
        flex-direction: column;
    }

    /* .claims_page select.form-select {
        width: 15% ;
    } */

    .itemsPage {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .claims_table {
        width: auto;
        overflow-x: auto;
    }

    .claim_top_btns {
        display: flex;
        /* flex-direction: column; */
        padding: 10px 0;
    }

    /* .nav_total {
        width: 83%;
    } */
    .claims_card .card {
        width: 100%;
    }

    /* .claim_pagination.allclaims {
        flex-direction: row;
    } */

    .allclaims .itemsPage {
        width: 53% !important;
    }

    .active {
        width: 100%;
    }

    .lmenu {
        display: none;
    }

    .claim_footer {
        flex-direction: column-reverse;
        height: 65px;
        align-items: center;
    }

    .claims_card {
        margin-bottom: 7rem !important;
    }

    .claim_top_btns .claim_btn {
        padding: 5px 5px;
    }

    .claim_top_btns .filter_btnn {
        padding: 4px 7px;
    }

    .control_search form {
        width: 195px;
    }

    .warrtylog {
        padding: 4px 5px;
    }
}

@media (max-width:767px) {

    .userSearch .control_search form input {
        width: 265PX;
        float: left;
        /* position: relative; */
    }

    .control_search form img {
        display: none;
    }

    .filres {
        width: 70% !important;
    }

    .filter_btns {
        justify-content: flex-start;
    }

    .werwerwe {
        display: none;
    }

    .mob_data_lable label {
        font-family: Arial;
        font-size: 16px;
        font-weight: 600;
        line-height: 15.25px;
        text-align: left;
    }

    .draft_color {
        font-family: Arial;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.42px;
    }

    .mob_data_lable img {
        width: 20px;
    }

    .card_data .card {
        margin-bottom: 20px;
    }

    /* .claims_card .card {
        display: none;
    } */
    .claim_pagination {
        justify-content: space-between;
        padding: 0 10px;
        margin: 0 0 15px;
        flex-direction: row;
    }

    .itemsPage select {
        width: 30% !important;
    }

    .card_pagination {
        display: none;
    }

    /* .draft_color {
        border-bottom: 1px solid #E4F2FF;
    }

    .sub_color {
        border-bottom: 1px solid #E4F2FF;
    } */

    .claim_pagination .itemsPage {
        width: 67% !important;
    }

    .desc_table {
        display: none;
    }

    .Card_top {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        border-bottom: 1px solid #E4F2FF;
    }

    .edit_icon_card img {
        width: 25px;
    }

    /* .card_top {
        margin-top: 20px;
    } */

    .page_wid {
        display: flex;
        gap: 7px;
        align-items: baseline;
    }

    p.draft_color {
        font-weight: 600;
    }

    p.sub_color {
        font-weight: 600;
    }

    .logOut_pop {
        align-items: center;
    }

    .mob_card {
        display: block;
    }

    .tmenu {
        display: block;
    }

    /* .nav_total {
        width: 70px;
    } */

    .details_All {
        background-color: #f1f1f1;
        padding: 0 45px 0 10px;
    }

    .claims_table.table-responsive .card-body {
        padding: 5px 10px 15px;
    }

    .claims_table .row.card_top {
        position: relative;
        float: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        margin: 0 0 1rem;
    }

    /* .claims_table .row.card_top:last-child {
        margin: 0 0 0;
    } */

    .claims_table .row.card_top .col-sm {
        padding: 0 5px;
        max-width: 50%;
        flex: 0 0 50%;
    }

    .claims_table .row.card_top .col-sm .mob_data_lable {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        font-size: 14px;
        word-break: break-all;
        margin-left: 5px;
    }

    .claims_table img {
        width: 1.3rem;
        cursor: pointer;
        margin-left: 0.7rem;
    }

    .claims_table .row.card_top .col-sm.w-100 {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .mob_data_lable {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        font-size: 14px;
        word-break: break-all;
    }

    .page_wid span {
        display: block;
    }

    .claims_table {
        border-radius: 0px;
    }

    .claim_top_btns.rma_top_btns {
        /* flex-direction: column; */
        /* align-items: flex-start; */
        gap: 0;
    }

    .sideMenu {
        flex-direction: column;
    }

    /* .claim_top_btns .filter_btnn img {
        display: none;
    } */

    /* .claim_top_btns .claim_btn img {
        display: none;
    } */

    .claims_card {
        margin-bottom: 7rem !important;
    }
}

@media (max-width:576px) {
    .claim_top_btns .claim_btn img {
        display: none;
    }

    .claim_top_btns .filter_btnn img {
        display: none;
    }

    .claim_pagination {
        flex-direction: column;
    }

    .itemsPage select {
        width: 60% !important;
    }

    .filter_btns {
        gap: 5px;
    }

    .filter_btns .clr_btn {
        padding: 5px 5px;
        border-radius: 6px;
    }

    .filter_btns .filCan {
        padding: 5px 5px;
        border-radius: 6px;
    }

    .filter_btns .can_btnClm {
        padding: 5px 5px;
        border-radius: 6px;
    }

    button.addclaim-sub-btn {
        padding: 5px 5px;
        border-radius: 6px;
    }

    /* .claimPop {
        width: 100% !important;
    } */

    .claim-pop_left {
        width: 30%;
    }

    .filres {
        width: 105% !important;
    }

    button.cancal-sub-btn {
        color: #000;
    }

    .claims_table {
        border-radius: 0px;
    }

    .lmenu {
        display: none;
    }

    .sideMenu {
        flex-direction: column;
    }

    .nav_total {
        /* width: 70px; */
        display: flex;
        flex-direction: row;
        height: 65px;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    .claims_card {
        margin-bottom: 7rem !important;
    }
}


@media screen and (max-width: 425px) {
    .react-datepicker {
        width: 100% !important;
    }

    .react-datepicker__month-container {
        float: initial !important;
    }

    .react-datepicker__day-names {
        white-space: normal !important;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 1.5rem !important;
        font-size: 12px !important;
    }

    .react-datepicker__month {
        margin: 0.3rem !important;
    }

    .react-datepicker__week {
        display: flex !important;
    }

    .claim-pop_left ul li {
        padding: 35px 10px 0 15px;
        font-size: 14px;
        line-height: 26px;
    }

    .claimPop .claim-popTotal {
        margin: 0 5px;
    }

    .claim-pop_right {
        padding: 25px 10px 25px 5px;
        height: 300px;
    }

    button.cancal-sub-btn {
        color: #000;
    }

    .claims_table {
        border-radius: 0px;
    }

    .lmenu {
        display: none;
    }

    .claims_card {
        margin-bottom: 7rem !important;
    }
}

@media screen and (max-width: 320px) {
    .react-datepicker {
        width: 75% !important;
    }

    button.cancal-sub-btn {
        color: #000;
    }

    .claims_table {
        border-radius: 0px;
    }

    .lmenu {
        display: none;
    }

    .claims_card {
        margin-bottom: 7rem !important;
    }
}
