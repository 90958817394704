.upload_card {
    margin-top: 30px;
}

.upload_card .card {
    width: 60%;
}

.upload_title p {
    color: #000000;
    font-family: 'Arial';
    font-size: 20px;
    font-weight: 600;
    line-height: 23.46px;
    text-align: left;
}

.upload_file {
    padding: 75px 0;
    border-style: dotted;
    border-radius: 6px;
    border-color: rgb(191 191 191);
    position: relative;
}

.upload_file input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    text-align: center;
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;
}

.upload_file img {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

.format_acct {
    font-family: 'Arial';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: #7D7D7D;
    margin-top: 20px;
}

.upload_sample {
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: left;
    color: #2D2D2D;
    width: 40%;
}

.uplo_btn {
    display: flex;
    align-items: center;
}

.upl_arrow {
    width: auto;
}

.upl_btn {
    background: #000;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    color: white;
    align-items: center;
    padding: 9px 20px;
    gap: 10px;
    display: flex;
    cursor: pointer;
}

.uplo_btn a {
    text-decoration: none;
}

.upload_btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.upload_file p {
    color: rgb(0, 0, 0);
    text-align: center;
    margin-bottom: 0px;
}

.down_samp {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    border: 1px solid #D9D9D9;
    width: 90%;
    padding: 5px;
    border-radius: 10px;
}

.down_samp a {
    text-decoration: none;
    color: #7D7D7D;
    font-size: 14px;
}

.upload_file .file_name {
    color: #3D9C57;
}

@media (max-width: 767px) {
    .upload_card .card {
        width: 100%;
    }

    .upload_card {
        margin-bottom: 100px;
    }

    .down_samp {
        width: 49%;
    }

    .upload_sample {
        width: unset;
    }
}

@media (max-width:576px) {

    .upload_card .card {
        width: 100%;
    }

    .upload_card {
        margin-bottom: 100px;
    }

    .down_samp {
        width: 63%;
    }

    .upload_sample {
        width: unset;
    }
}

@media screen and (max-width: 425px) {
    .down_samp {
        width: 70%;
    }

    .upload_sample {
        width: unset;
    }
}