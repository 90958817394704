.avatar_img img {
    width: auto;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.profile_data a {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-top: 1px solid #2d2d2d;
    border-bottom: 1px solid #2d2d2d;
    gap: 10px;
    flex-direction: row;
    text-decoration: none;
}

.profile_data_admin {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-top: 1px solid #2d2d2d;
    border-bottom: 1px solid #2d2d2d;
    gap: 10px;
    flex-direction: row;
    text-decoration: none;
}

.user_data p {
    color: #fff;
    font-family: Arial;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
}

.user_data span {
    font-family: Arial;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.max_logo img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.nav_total {
    background-color: black;
    /* width: 20%; */
    height: 100vh;
    padding: 15px 10px;
    flex-direction: column;
}

.navContent a {
    display: flex;
    align-items: center;
    gap: 10px;
    /* padding: 15px 15px 0; */
    text-decoration: none;
}

.contentName {
    margin-top: 10px;
}

.contentName p {
    font-family: Arial;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: auto;
    margin-bottom: 0;
}
.contentName a {
    font-family: Arial;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: auto;
    margin-bottom: 0;
}
.navContent img {
    cursor: pointer;
}

.logOut_pop img {
    cursor: pointer;
    width: auto;
}

.logOut_pop {
    display: flex;
    /* align-items: baseline; */
    gap: 10px;
    padding: 10px 10px;
}

.leftBar_main {
    position: relative;
    background-color: black;
    /* width: 20%; */
}

/* 
a.hide_icon {
    position: absolute;
    right: 0;
} */
div#collapseExample {
    background-color: black;
}

.collapsing {
    height: 100vh;
    overflow: hidden;
    transition: width 0.35s ease !important;
}

.modal-body {
    text-align: center;
    color: #000000;
}

.modal-footer {
    justify-content: center;
}

.modal-content {
    background-color: #ffff;
    border: 2px solid #fff100;
    /* color: #fff100 !important; */
}

.log_title {
    color: #000000 !important;
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.46px;
}

button.btn-close {
    background-color: #FFFFFF;
}

.no_btn {
    padding: 5px 30px;
    /* background-color: #FFF100; */
    /* color: #000000; */
    /* border: solid #FFF100; */
}

button.btn.btn-primary {
    padding: 5px 27px;
}

button.btn.logyesbtn {
    border: 1px solid #000;
    background-color: #000;
    color: yellow;
    font-weight: 600;
    padding: 7px 25px;
    font-size: 14px;
}

.navContent a {
    width: 100%;
}

.max_logo img {
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .contentName p {
        display: none;
    }

    .profile_data a {
        display: flex;
        flex-direction: column;
    }

    .profile_data {
        flex-direction: column;
    }

    .nav_total hr {
        display: none;
    }

    /* .leftBar_main {
        background-color: #ffff;
    } */
    .user_data p {
        display: none;
    }

    /* .max_logo img {
        display: none;
    } */

    .profile_data_admin {
        /* flex-direction: column;
        align-items: baseline; */
        display: none;
    }

    .user_logout {
        width: 70%;
        margin-right: auto;
        margin-left: auto;
    }

    .profile_data a {
        gap: 0;
    }

    .logOut_pop {
        padding: 0;
    }

    .navContent a {
        gap: 0;
    }

    .profile_data a {
        padding: 0;
    }

    .logOut_pop img {
        width: auto;
    }

    .profile_data img {
        width: 25px;
    }

    .nav_total {
        /* width: 70px; */
        display: flex;
        flex-direction: row;
        height: 65px;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 0 30px;
    }

    .logOut_pop {
        /* display: flex;
        align-items: flex-end; */
        gap: 0px;
    }

    /* .profile_data a {
        border-top: unset;
        border-bottom: unset;
    } */
    .max_logo img {
        margin-bottom: 0px;
    }
    
}