.add-returns-container {
    display: flex;
}

.row.ret_top {
    padding: 15px 0px;
}

.ret_hr hr {
    margin: 0;
}

.return_basic p {
    font-family: Arial;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

.row.section_gap {
    margin-bottom: 20px;
}

.return_details .card-body {
    padding: 20px;
}

.ret_top h3 {
    color: #000000;
    font-family: Arial;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;

}

.return_btns {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.return_btns .rtn_can {
    border: 1px solid #000000;
    border-radius: 6px;
    background-color: #ffff;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
}

.return_btns .rtn_draft {
    border: 1px solid #000000;
    border-radius: 6px;
    background-color: #ffff;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
}

.return_btns .rtn_submit {
    border: 1px solid #000000;
    border-radius: 6px;
    background-color: #000000;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #fff;
}

.rtn_part_title p {
    /* background-color: black;
    color: #fff !important; */
    width: 100px;
    padding: 5px 20px;
    cursor: pointer;
    border: 1px solid;
}

.rtntabs {
    margin-bottom: 20px;
}

.basic_return {
    margin-bottom: 30px;
}

.return_back {
    padding: 10px 0;
    cursor: pointer;
}

.field_section .inactive {
    display: none;
}

.return_details {
    margin-bottom: 5rem;
}

.rtneditcard {
    margin-bottom: 5rem;
}

.parts_section {
    border: 1px solid #D4DAE8;
    padding: 25px;
}

.returns_suc {
    top: 10px;
    right: 35px;
}

.invo_class {
    color: red;
}

.repair-intabs.tabsspace {
    margin-right: 3px;
    margin-bottom: 3px;
}

button.addrepairbtn.rtnplus {
    margin-left: 0px !important;
}

.col-md-6.involine {
    display: none;
}

.rma_parts button {
    width: 100px;
}

small.Errmsg.Warrenty.Popup.prtno {
    top: 100px;
}

.toaster.fail.mdesc.rtnerr {
    margin-top: 85px;
}

small.Errmsg.Warrenty.Popup.invopaart {
    margin-top: 32px;
}

button.rtn_draft[disabled] {
    opacity: 0.5;
}

button.rtn_submit[disabled] {
    opacity: 0.5;
}

p.hidetext {
    margin: 20px 0;
    font-style: italic;
    font-family: 'Arial';
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: #7c7c7c;
    margin-bottom: 0;
}

.basic_return.rtncard {
    margin-bottom: 0;
}

.ret_top {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 5px 0;
}

.return_basic.rma_file {
    margin: 30px 0;
}

.file-class {
    padding: 20px;
    border: 1px solid #D4D9E8;
    margin-top: 20px;
}

@media (max-width:767px) {
    .return_btns .rtn_can {
        padding: 5px 5px;
    }

    .return_btns .rtn_can img {
        display: none;
    }

    .return_btns .rtn_draft {
        padding: 5px 10px;
    }

    .return_btns .rtn_draft img {
        display: none;
    }

    .return_btns .rtn_submit img {
        display: none;
    }

    .row.section_gap .col-md-6 {
        margin-bottom: 20px;
    }

    .row.section_gap {
        margin-bottom: 0;
    }

    .add-returns-container {
        flex-direction: column;
    }
}

@media (max-width:576px) {
    .add-returns-container {
        flex-direction: column;
    }

}