.home_total {
    display: flex;
    flex-direction: column;
}

.home_top {
    background-color: #0000;
    height: 60px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home_sm_box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.home_main {
    background-color: #f1f1f1;
    height: 100vh;
    position: relative;
}

.btns_home {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}


.Max_box .card-body {
    width: 100%;
    padding: 30px 80px;
}

.Max_box a {
    text-decoration: none;
}

.Max_box p {
    margin: 1rem 0;
    font-family: Arial;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.46px;
    text-align: center;

}

.view_btn button {
    /* width: 100%; */
    background-color: #000000;
    color: #fff;
    padding: 10px 50px;
}

.home_img img {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.card-body.rma_card {
    padding: 30px 80px;
}

.Max_box {
    position: relative;
}

/* .Max_box a {
    position: relative;
} */

.Max_box:before {
    height: 10px;
    width: 80%;
    content: "";
    background-color: rgb(0, 0, 0);
    position: absolute;
    top: -0.6rem;
    left: 50%;
    transform: translate(-50%, -0px);
    border-radius: 15px 15px 0 0;
}

.home_footer {
    padding: 10px;
    color: #fff;
    background-color: #000000;
    height: 50px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    bottom: 0;
}

.home_content a {
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}

.home_content {
    display: flex;
    gap: 30px;
}

.home_bold {
    margin-top: 30px;
}

.home_bold p {
    margin: 10px;
    text-align: center;
    font-family: 'Arial';
    font-size: 23px;
    font-weight: 600;
    line-height: 26.98px;
    text-align: center;
}

.small_top_content {
    width: 42%;
    font-family: Arial;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.small_top_content span {
    color: #373737;
    font-family: Arial;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
}

.footer_copy p {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
}

.modal-content.home_logout_pop {
    background-color: #ffff !important;
}

.Max_box .card-body:hover {
    border: 1px solid;
    border-radius: 3px;
}

.home_log {
    display: flex;
    align-items: center;
    gap: 3px;
}

.home_log img {
    cursor: pointer;
}

.contentName.home_p {
    margin-top: 7px;
}

@media (max-width:767px) {
    .home_top img {
        display: block;
    }

    .btns_home {
        flex-direction: column;
        margin-bottom: 100px;
    }

    .small_top_content {
        width: 80%;
    }

    .home_main {
        height: unset;
    }

    .home_footer {
        flex-direction: column-reverse;
        height: 80px;
        align-items: center;
    }

    .footer_copy p {
        margin-bottom: 0px;
    }

    .home_log {
        gap: 0;
    }

    .Max_box:before {
        position: absolute;
    }
}

@media (min-width:767px) {
    .btns_home {
        margin-bottom: 50px;
    }

    .home_footer {
        height: 50px;

    }

    .Max_box:before {
        position: absolute;
    }

}

@media (min-width:576px) {

    .Max_box a:before {
        position: absolute;
    }
}

@media (min-width:767px) and (-webkit-min-device-pixel-ratio:0) {

    .Max_box:before {
        position: absolute;
    }

}

@media (min-width:576px) and (-webkit-min-device-pixel-ratio:0) {

    .Max_box a:before {
        position: absolute;
    }

}