.user_topbar {
    display: flex;
    align-items: center;
}

.details_All {
    background-color: #f1f1f1;
}

.allclaims .itemsPage {
    width: 65%;
}

.allclaims {
    display: flex;
    align-items: baseline;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
}

.allclaims .itemsPage select {
    width: 15%;
    cursor: pointer;
}

.allclaims nav {
    width: 80%;
}

.mob_ser {
    display: none;
}

.claims_table tbody .lift_model {
    width: 275px;
    word-break: break-all;
}

@media (max-width: 767px) {
    .claims_table {
        width: auto;
        overflow-x: auto;
    }

    .claim_top_btns {
        display: flex;
        /* flex-direction: column; */
        padding: 10px 0;
    }

    /* .nav_total {
        width: 83%;
    } */
    .claims_card .card {
        width: 100%;
    }

    .desc_table {
        display: none;
    }

    .claimPop {
        width: 69%;
    }

    .table-responsive table th:first-child {
        min-width: 50px !important;
    }

    .table-responsive table th:nth-child(5) {
        min-width: 100px !important;
    }

    .table-responsive table th:nth-child(7) {
        min-width: 60px !important;
    }

    .table-responsive table th:nth-child(6) {
        min-width: 100px !important;
    }

    .mob_ser {
        display: block;
    }

    .desk_ser {
        display: none;
    }
}