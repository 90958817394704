.details_All {
    background-color: #f1f1f1;
}


.basic_info {
    padding: 20px;
}

.rep_labor {
    padding: 30px 0;
}

.dropDowns {
    border: 1px solid;
}

/* .parts_all {
    padding: 30px;
    border: 1px solid #D9D9D9;
} */

.details_Total {
    display: flex;
}

.details_menu {
    /* width: 20%; */
    background-color: black;
}

.rep_labor p {
    word-break: break-all;
}

.details_dropdown select {
    width: 55%;
    border-radius: 10px;
    padding: 10px 9px;
    cursor: pointer;
}

.baic_info_details {
    margin: 20px 0px;
}

.details_Total label {
    font-family: Arial;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    color: #000000;
}

.details_Total .count-details {
    padding: 0 15px;
}

.summ span {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Arial';
}

.summ {
    margin-bottom: 0;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Arial';
}

/* .details_Total p {
    font-family: HelveticaNeueMedium;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: left;
    color: #181A1B;
} */
.claimView p {
    font-family: Arial;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #000000;
    margin: 0px 0;
}

.repair_count p {
    font-family: Arial;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    color: #000000;
}

.partsHead {
    font-family: Arial;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #000000;
    margin: 11px 0 1.2rem;
}

.multicard {
    margin-bottom: 5rem;
}

.parts_all {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.parts_total {
    flex: 1 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 0 1rem;
}


.parts_data {
    padding: 0 0;
    border: 1px solid #d9d9d9;
}

.parts_total .parts_count {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
    color: #000000;
    margin-bottom: 0.7rem;
    padding: 20px 15px 10px;
    border-bottom: 1px solid #d9d9d9;
}

.parts_box {
    border: 1px solid #D4DAE8;
    padding: 20px;
}

.previous_icon {
    padding: 15px 0;
    cursor: pointer;
}

.previous_icon a {
    cursor: pointer;
    text-decoration: none;
    color: #282828;
    font-family: Arial;
    font-size: 16px;
    font-weight: 300;
    line-height: 15.6px;
    /* display: flex;
    gap: 10px; */
}

.lab_data {
    display: flex;
    justify-content: space-between;
}

.labor_content .subName_lab {
    padding: 20px 0 10px 20px;
}

.subName_lab p {
    font-family: Arial;
    font-size: 20px;
    font-weight: 500;
    line-height: 18px;
    color: #7E7E7E;
    margin-bottom: 0;
}

.labor_content {
    border: 1px solid #D9D9D9;
    /* padding: 20px 20px 0; */
    margin: 0 0 1rem;
}

.lab_box_data {
    padding: 0px 20px;
}

.repParts {
    padding: 30px;
    font-family: Arial;

}

.mic_desc p {
    margin-bottom: 0;

}

.previous_icon span {
    color: #282828;
    font-size: 13px;
    font-weight: 500;
    font-family: 'Arial';
}

.mic_desc .readMore {
    cursor: pointer;
    color: blue;
}

p.prt_desc {
    height: 60px;
}

.remove_file a {
    display: flex;
    gap: 5px;
    align-items: self-start;
}

.remove_file img {
    width: 20px;
}

.clmdtllog {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.part-reason {
    display: flex;
    border-top: 1px solid #d9d9d9;
    padding: 10px 0;
}

.part-grid {
    border-right: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
}

.part-grid-top {
    border-bottom: 1px solid #d9d9d9;
}

.labour-grid {
    border-right: 1px solid #d9d9d9;
}

.labour-table {
    border-color: #ffff;
}

.labour-table .labour-head {
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #D9D9D9;
}

table.table.labour-table {
    table-layout: fixed;
    margin: 0 0;
}

table.table.labour-table th,
table.table.labour-table td {
    border-right: 1px solid #ececec;
}

table.table.labour-table tr th:last-child,
table.table.labour-table tr td:last-child {
    border-right: none;
}

.bt {
    border-top: 2px solid #f1f1f1;
}

.col-status {
    color: #3D9C57;
}

.col-status span {
    color: #000000;
}

.status-process {
    color: rgb(167, 0, 167);
}

.status-process span {
    color: #000000;
}

.status-rejected {
    color: red;
}

@media (max-width: 767px) {

    .parts_total {
        max-width: 100%;
    }

    .col {
        flex: 100%;
    }

    .parts_total {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .baic_info_details {
        margin-top: 10px;
    }

    .baic_info_details .card-body {
        padding: 20px;
    }

    .repParts {
        padding: 20px;
    }

    .subName_lab {
        padding: 15px !important;
    }

    .labor_content {
        margin: 1rem 0 1rem;
    }

    .msc_mob {
        display: none;
    }

    .mic_desc p {
        margin-bottom: 1rem;
    }

    .details_Total {
        flex-direction: column;
    }

    .multicard {
        margin-bottom: 7rem;
    }

    .csdcsdcsdf {
        display: flex;
    }
}



@media (max-width:576px) {
    .subName_lab {
        padding: 15px !important;
    }

    .labor_content {
        margin: 1rem 0 1rem;
    }

    .mic_desc p {
        margin-bottom: 1rem;
    }

    .msc_mob {
        display: none;
    }

    .details_Total {
        flex-direction: column;
    }

    .multicard {
        margin-bottom: 7rem;
    }
}