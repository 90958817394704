/* Position the checkbox in upper left corner & hide */

ul#pillss-tab1 button {
    border: 1px solid #000;
    border-radius: 0px;
    color: #000;
    margin-right: 2px;
}

.details_All {
    position: relative;
}

.basic-info-fields {
    background-color: #fff;
    /* margin: 10px 0px; */
    box-shadow: 0px 6px 24px 0px #72939A29;
    padding: 40px 25px;
    border-radius: 8px;
}

.repair-details .inactive .row,
.repair-details .inactive {
    display: none;
}

.part-details .inactive .row,
.part-details .inactive {
    display: none;
}

.Repairs_fields h4 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
}

.Repairs_fields {
    border-radius: 8px;
    background-color: #fff;
    margin: 10px 0px;
    padding: 40px 25px;
    box-shadow: 0px 6px 24px 0px #72939A29;
}

.addclaim-container hr {
    margin: 20px 0px !important;
}

.Repairs_fields .container {
    padding: 0px;
}

.card.container.add_card_total .card-body {
    padding: 0px;
}

.card.container.add_card_total {
    background-color: unset;
    border: none;
    padding: 0px !important;
}

.nav-pills .nav-link {
    border-radius: 0px;
    color: #000;
    margin-right: 2px;
    border: 1px solid #000;
    position: relative;
}

.nav-link span.close {
    width: 15px;
    position: absolute;
    top: -10px;
    color: #fff;
    font-weight: 600;
    right: -1px;
    font-size: 10px;
    height: 15px;
    border-radius: 50%;
    background-color: red;
    display: block;
}

small.Errmsg {
    color: red;
    position: fixed;
    top: 30%;
    right: 40px;
    z-index: 9999;
}

input[type=checkbox] {
    position: absolute;
    visibility: hidden;
}

.addclaim-container {
    display: flex;
}

button.addmorebtn {
    padding: 2px 15px;
    font-size: 24px;
    border: 1px solid #7D7D7D;
    top: 0px;
    position: relative;
    background-color: #fff;
    height: unset;
}

button.savedraft-btn {
    padding: 5px 15px;
    background-color: #fff;
    border: 1px solid #000;
}

h4 {
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.46px;
    text-align: left;
}

button.cancal-sub-btn {
    padding: 4px 15px;
    background-color: #fff;
    border: 1px solid #000;
}

.btn-group.addclaim {
    display: flex;
    gap: 10px;
    margin-top: 40px;
}

button.addclaim-sub-btn {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 5px 15px;
}

.addclaim-container .card.container {
    padding: 10px 15px;
    /* margin: 30px 0px; */
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #000;
    color: #fff !important;
}

.nav-pills li:first-child span.close {
    display: none;
}

ul#pills-tab .nav-link {
    border: 1px solid #000;
    border-radius: 0px;
    margin-right: 2px;
    position: relative;
    color: #000;
}

span.tit-repair {
    display: inline-block;
    padding: 10px 20px;
    margin-right: 2px;
    color: #fff;
    text-align: center;
    background-color: #000;
    min-width: 120px !important;
}

.leftBar_main .modal-content {
    background-color: #fff !important;
}

/* Styling Parts */
#part1,
#part2,
#part3 {
    display: block;
    opacity: 1;
    transition: all 1s ease-in-out;
}


/* Hide Parts 2 & 3 on Page Load */
#part2,
#part3 {
    opacity: 0;
    height: 0;
    overflow: hidden;
}


/* Show Step 2 */
#step2:checked~#part2 {
    opacity: 1;
    height: auto;
}

/* Hide Step 1 */
#step2:checked~#part1 {
    opacity: 0;
    height: 0;
}

/* Show Step 3 */
#step3:checked~#part3 {
    opacity: 1;
    height: auto;
}

.claim_top_bar {
    border-bottom: 1px solid #D4DAE8;
}

/* Hide Step 2 */
#step3:checked~#part2 {
    opacity: 0;
    height: 0;
}

input[readonly] {
    background-color: #ddd;
}

input[readonly]:focus {
    background-color: #ddd;
}

.mdesc {
    margin-top: 55px;
}

.subName p {
    font-family: Arial;
    font-size: 20px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.multiform .col-md-6 {
    padding: 10px;
}

.multiform .col-md-4 {
    padding: 10px;
}

.multiform .col-md-2 {
    padding: 10px;
}

.multiform .col-md-3 {
    padding: 10px;
}

.add_repair_btn button {
    padding: 5px 10px;
}

.parts_fileds {
    padding: 20px;
    border: 1px solid #D4DAE8;
}

.add_claim_title p {
    font-family: Arial;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.46px;
    text-align: left;
    color: #000000;
    /* margin: 20px 0; */
}

.row.repair_Content {
    margin-top: 30px;
}

.user_topbar {
    display: flex;
    align-items: center;
}

.form-select option {
    margin: 30px;
}

.repair-tabs button,
.part-tabs button {
    width: 120px;
}

.repair-tabs button.active,
.part-tabs button.active {
    background-color: #000;
    color: #fff !important;
}

.custom-select {
    position: relative;
    font-family: Arial;
}


.select-selected {
    background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

.repair-tabs button,
.part-tabs button {
    position: relative;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #000;
    text-align: left;
    color: #000;
}

.addrepairbtn {
    padding: 10px 15px !important;
    width: 50px !important;
    height: 46px !important;
}

button.addpartbtn {
    width: 50px !important;
    text-align: center;
}

.Repairs_fields .container+hr {
    margin-top: 60px !important;
}

.repair-details .active h4 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
}

.part-tabs-border {
    padding: 15px 20px;
    border: 1px solid #D4DAE8;
}

.part-details>.active {
    margin-top: 0px;
}

.part-tabs button span.close {
    color: #000;
}

.part-tabs button.active span.close {
    color: #fff;
}

.part-tabs .close,
.repair-tabs span.close {
    top: 12px;
    width: 15px;
    height: 15px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    border: none;
    border-radius: 50%;
    right: 20px;
    margin: auto;
    position: absolute;
    color: #fff;
}

.known-optin[data-val='partnochecked'] input[type='radio']::before {
    position: absolute;
    content: '';
    width: 7.5px;
    height: 7.5px;
    top: 2.69px;
    left: 2.42px;
    border-radius: 50%;
    background-color: blue;
}

.known-optin.partdesc[data-val='partnochecked'] input[type='radio']::before {
    content: unset !important;
}

.known-optin[data-val='partnochecked'] input[type='radio'] {
    position: relative !important;
}

.known-optin.partno[data-val='partdescchecked'] input[type='radio']::before {
    content: unset !important;
}

.known-optin[data-val='partdescchecked'] input[type='radio'] {
    position: relative !important;
}

.known-optin.partdesc[data-val='partdescchecked'] input[type='radio']::before {
    position: absolute;
    content: '';
    width: 7.5px;
    height: 7.5px;
    top: 2.69px;
    left: 2.42px;
    border-radius: 50%;
    background-color: blue;
}

.known-opt>label {
    margin: 15px 0px 7px 0px;
}

.known-optin-top {
    display: flex;
    gap: 20px;
}

.known-optin-top input {
    margin-right: 5px;
}

/* .repair-intabs:first-child .close {
    display: none;
} */

.repair-tabs button+button.repair-tabss.close.active {
    background-color: transparent;
    color: #000 !important;
}

.repair-tabs button.active+button.repair-tabss.close.active {
    color: #fff !important;
}

button.addpartbtn {
    margin-left: 0px;
}

.part-tabs {
    display: flex;
    flex-flow: wrap;
    gap: 0px;
}

::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #939393 !important;
}

button.addrepairbtn {
    margin-left: 0px;
    text-align: center;
}

.part-tabs.clmparts button {
    margin-right: 3px;
    margin-bottom: 3px;
}

.toaster.suc.clmtoster {
    top: 65px;
}

small.Errmsg.Warrenty.Popup {
    background-color: red;
}

::placeholder {
    color: #939393 !important;
}

.repair-tabs {
    display: flex;
    flex-flow: wrap;
    gap: 0px;
    margin-bottom: 15px;
}

small.errMsg {
    top: -2px !important;
}

.repair-intabs {
    position: relative;
    background-color: #000;
    color: #fff !important;
    /* margin-left: 5px; */
}

button.repair-tabss.close.active {
    position: absolute;
    top: 12px;
    height: 15px;
    width: 15px;
    padding: 0px;
    font-size: 16px;
    border: none;
    border-radius: 50%;
    right: 10px;
    margin: auto;
    /* background-color: red; */
}

.details_All {
    background-color: #f1f1f1;
    padding: 0 30px;
}

.add_card_total {
    margin-bottom: 20px;
}


/* .dropdown {
    position: relative;
    color: #333;
    cursor: default;
  }
  
  .dropdown .arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: 0.3rem;
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
  }
  
  .dropdown .arrow.open {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }
  
  .dropdown .selected-value input {
    line-height: 1.5;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    width: 100%;
  }
  
  .dropdown .options {
    display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }
  
  .dropdown .options.open {
    display: block;
  }
  
  .dropdown .option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }
  
  .dropdown .option.selected,
  .dropdown .option:hover {
    background-color: #f2f9fc;
    color: #333;
  } */

small.Warrenty.Popup {
    text-transform: none;
    margin: 0px;
    font-size: 18px;
    padding: 3px 10px 0px 10px;
    font-weight: 600;
}

.Warrenty.Popup {
    position: fixed;
    border-radius: 5px;
    background-color: orange;
    color: #fff;
    top: 30px;
    right: 30px;
    z-index: 999;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.dollarsign {
    position: relative;
}

.dollarsign:before {
    content: '$';
    position: absolute;
    top: 7px;
    left: 10px;
}

.dollarsign input {
    padding-left: 22px;
}

.toaster.suc.pop {
    width: 200px;
    right: 30px;
    background-color: yellow;
}

.toaster.suc.pop h2 {
    color: #000 !important;
}

span.RefNo {
    font-size: 20px;
    font-weight: 600;
}

.lable_cs label {
    /* color: red; */
    font-family: Arial;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    /* display: none; */
    /* opacity: 0; */
    -webkit-appearance: none;
}

input[type="date"] {
    position: relative;
    padding-right: 2.3rem;
    z-index: 2;
}

input#session-date {
    display: inline-block;
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    right: 1rem;
    top: 50%;
    transform: translate(0.5rem, -50%);
    width: 1.5rem;
    height: 1rem;
}

input[type="date"]::after {
    content: "";
    background: url(../assets/dateicon.svg) no-repeat;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0.5rem, -50%);
    width: 1.5rem;
    height: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: -1;
}

.webdate input[type="date"]::after {
    display: none;
}

.purchase_date input[type="date"]::after {
    display: none;
}

p.file_format {
    margin-bottom: 10px;
    font-size: 13px;
}

.claims_card {
    margin-bottom: 5rem;
}

.Repairs_fields {
    margin-bottom: 5rem;
}

@media (max-width:767px) {
    .addclaim .cancal-sub-btn img {
        display: none;
        padding: 4px 5px;
    }

    .addclaim .savedraft-btn img {
        display: none;
        padding: 5px 5px;
    }

    .addclaim .addclaim-sub-btn img {
        display: none;
        border-radius: 0px !important;
    }

    .lable_cs input {
        text-align: left;
    }

    input:is[type="date"] {
        text-align: left;
    }

    .addclaim-container {
        flex-direction: column;
    }

    .Warrenty.Popup {
        top: 110px;
    }

    .invo_file {
        flex-direction: column;
    }
}

@media (max-width:576px) {
    .lable_cs input {
        text-align: left;
    }

    .CanPop .can_popTotal {
        width: 75% !important;
    }

    button.cancal-sub-btn {
        color: #000 !important;
    }

    button.savedraft-btn {
        color: #000;
    }

    .cancel_btns .can_no {
        padding: 5px 15px;
        color: #000;
        background-color: #fff;
        border: 1px solid;
    }

    .known-optin.partdesc[data-val=partdescchecked] input[type=radio]:before,
    .known-optin[data-val=partnochecked] input[type=radio]:before {
        left: 4.52px;
        top: 3.75px;
    }

    .known-optin[data-val=partnochecked] input[type=radio]:before {
        height: 8.5px;
    }

    .known-optin[data-val=partdescchecked] input[type=radio]:before {
        height: 8.5px;
    }


    .addclaim .cancal-sub-btn img {
        display: none;
        padding: 4px 5px;
        border-radius: 6px !important;
    }

    .addclaim .cancal-sub-btn {
        border-radius: 6px !important;
    }

    .addclaim .savedraft-btn img {
        display: none;
        padding: 5px 5px;
    }

    .addclaim .savedraft-btn {
        border-radius: 6px !important;
    }

    .addclaim .addclaim-sub-btn img {
        display: none;
        border-radius: 0px !important;
    }

    input:is[type="date"] {
        text-align: left;
    }

    .addclaim-container {
        flex-direction: column;
    }

    .Warrenty.Popup {
        top: 110px;
    }

    .invo_file {
        flex-direction: column;
    }
}

/* @media screen and (max-width: 425px) {
    .CanPop .can_popTotal {
        width: 65%;
    }

} */