.userlist_total {
    display: flex;
}

.claim_pagination {
    display: flex;
}

.itemsPage {
    display: flex;
    align-items: baseline;
    gap: 5px;
    width: 65%;
}

/* .itemsPage select {
    width: 30%;
    cursor: pointer;
} */

ul.pagination {
    display: flex;
    flex-flow: wrap;
}

li.page-item {
    padding-bottom: 10px;
}

/* .users_table td button {
    background-color: #000;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    border: none;
    padding: 2px 10px;
} */

.approve .modal-title {
    color: #000000 !important;
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.46px;
}

.approve_total .approveNo {
    padding: 5px 30px;
    color: #000;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 23.46px;
    background-color: #fff;
    border: 1px solid;
}

.approve_total .approveYes {
    padding: 5px 30px;
    color: #fff;
    background-color: #000;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 23.46px;
}

.approve_total .modal-body {
    text-align: center;
    color: #000000;
    font-weight: 500;
    font-family: Arial;
    font-size: 18px;
}

.users_table img {
    width: 23px;
    cursor: pointer;
}

.user_topbar {
    display: flex;
    align-items: center;
}

.details_All {
    background-color: #f1f1f1;
}

.form-select option {
    font-size: 15px;
}

.users_table tbody .email_col {
    width: 200px;
    word-break: break-all;
}

.users_table tbody .user_name {
    width: 120px !important;
    word-break: break-all;
}

.users_table tbody .account_col {
    width: 150px;
    word-break: break-all;
}

.users_table.table-responsive {
    border-radius: 10px;
    background-color: #fff;
}

.mobVi {
    display: none;
}

.user_top_bar {
    justify-content: flex-end;
}

/* .User_count {
    display: none;
} */

.userListPagi select.form-select {
    width: 15%;
}

.admin_icons {
    display: flex;
    gap: 10px;
}

.appcolor {
    color: green;
}

.notappcolor {
    color: #ffa500;
}

.rejcolor {
    color: #EB4335;
}

@media (max-width:1024px) {
    .users_table tbody .email_col {
        width: 200px;
        word-break: break-all;
    }

    .table-responsive table th:first-child {
        min-width: 50px !important;
    }

    .userListPagi {
        display: flex;
        flex-direction: row;
    }

    .userListPagi .itemsPage {
        width: 100%;
    }

    th {
        min-width: 200px !important;
    }

    .user_Ui ul.pagination {
        display: flex;
        flex-flow: unset;
    }

    .claimPop.user_filter {
        width: 55%;
    }

    .userListPagi select.form-select {
        width: 30%;
    }
}

@media (max-width:767px) {
    .user_top_bar {
        justify-content: space-between;
    }

    .desk_view {
        display: none;
    }

    .userSearch .control_search form input {
        width: 195PX;
        float: left;
        /* position: relative; */
    }

    .control_search form img {
        display: none;
    }

    .userListPagi {
        display: flex;
        margin: 0 0 120px;
    }

    .gdgdfg {
        display: none;
    }

    /* .container,
    .container-sm {
        max-width: 604px;
    } */

    .profile_data_admin img {
        width: 20px;
    }

    .userListPagi .itemsPage select {
        width: 25%;
    }

    .container.details_All {
        padding: 0 15px;
        max-width: 100%;
    }

    .users_table.table-responsive .card-body {
        padding: 5px 10px 15px;
    }

    .users_table .row.card_top {
        position: relative;
        float: left;
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        margin: 0 0 1rem;
        min-height: 50px;
    }

    .users_table .row.card_top:last-child {
        margin: 0 0 0;
    }

    .users_table .row.card_top .col-sm {
        padding: 0 5px;
        max-width: 50%;
        flex: 0 0 50%;
    }

    .users_table .row.card_top .col-sm .mob_data_lable {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        font-size: 14px;
        word-break: break-all;
    }

    .Card_top {
        /* align-items: center; */
        margin: 0 0 1rem;
        padding: 0.3rem 0
    }

    .Card_top ul {
        padding: 0 0;
        margin: 0 0;
        list-style-type: none;
    }

    .Card_top p.draft_color {
        margin: 0 0;
    }

    .users_table img {
        width: 1.3rem;
        cursor: pointer;
        margin-left: 0.7rem;
    }

    .mob_data_lable label {
        font-size: 15px;
        line-height: initial;
    }

    .users_table .row.card_top .col-sm.w-100 {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .claim_top_bar {
        margin-top: 10px;
    }

    .claim_top_btns {
        align-items: center;
    }

    .modal-dialog.approve_total {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    .mobVi {
        display: block;
    }

    .User_count {
        display: block;
    }

    .user_filter {
        width: 100%;
    }

    .card_data {
        background-color: #f1f1f1;
    }

    .user_filter {
        width: 75% !important;
    }

    /* .modal-backdrop.show {
        opacity: 0;
    } */

    .filter_btns .filCan {
        color: #000;
    }

    .users_table.table-responsive {
        border-radius: 0px;
    }

    .modal-backdrop {
        position: unset;
    }

    .modal-backdrop.show {
        opacity: unset;
    }

    .userlist_total {
        flex-direction: column;
    }

    .mobser {
        display: none;
    }
}

@media (max-width:576px) {
    .user_filter {
        width: 100% !important;
    }

    .claim-pop_left {
        width: 30%;
    }

    .card_data {
        background-color: #f1f1f1;
    }

    .userListPagi {
        flex-direction: column;
    }

    .filter_btns {
        gap: 5px;
        bottom: -40px;
    }

    .filter_btns .clr_btn {
        padding: 5px 5px;
        border-radius: 6px;
    }

    .filter_btns .filCan {
        padding: 5px 2px;
        border-radius: 6px;
    }

    button.addclaim-sub-btn {
        padding: 5px 15px;
        border-radius: 6px;
    }

    .userListPagi .itemsPage select {
        width: 50% !important;
    }

    /* .modal-backdrop.show {
        opacity: 0;
    } */

    .filter_btns .filCan {
        color: #000;
    }

    .users_table.table-responsive {
        border-radius: 0px;
    }

    .modal-backdrop {
        position: unset;
    }

    .modal-backdrop.show {
        opacity: unset;
    }

    .userlist_total {
        flex-direction: column;
    }
    .mobser {
        display: none;
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    /* .modal-backdrop.show {
        opacity: 0;
    } */
}

.row.section_gap {
    margin-bottom: 20px;
}