@font-face {
    font-family: 'Arial';
    src: local('HelveticaNeueMedium'), url(./fonts/HelveticaNeueLight.otf) format('opentype');
}

@font-face {
    font-family: 'HelveticaNeueBold';
    src: local('HelveticaNeueBold'), url(./fonts/HelveticaNeueBold.otf) format('opentype');
}

div#root {
    height: 100vh;
    font-family: Arial;
}

.firstPara li {
    font-family: Arial;
    color: #060808;
    line-height: 1.6;
    font-size: 15px;
    letter-spacing: 0.2px;
    font-weight: 400;
}

:root {
    --mx-body-bg: #EDEFF4 !important;
}

/* div#root {
    height: 100vh;
} */
div#mob-user-tutor {
    color: #fff;
    font-family: Arial;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
}

.mobile-menu .container-fluid {
    padding: 0px;
}

.mobile-menu .container-fluid button {
    padding: 0px 3px;
}

.container-bg.pagenofound {
    height: 100vh;
}

.container-bg {
    background-color: #000000;
    background-image: url(../src/assets/grey-bg.png) !important;
    background-repeat: no-repeat;
    display: flex;
    background-size: 100%;
    height: 100%;
    /* min-height: 100vh; */
    flex-direction: column;
}

body {
    height: 100%;
    background-color: #000000 !important;
}

form.reset.form-area input {
    width: 100%;
}

.craft_serch.btn-area {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    gap: 20px;
}

button.btn.register-btn.reset-btn {
    margin: 0;
    /* background-color: var(--mx-body-bg) !important; */
}

.table {
    background-color: var(--bs-body-bg);
}

.bottom_bar_right a {
    color: #fff;
    text-decoration: none;
    font-family: Arial;
}

tr {
    height: 50px;
}

.craft_serch .resCan {
    border-radius: 6px;
    padding: 5px 12px;
    font-weight: 500;
    font-family: Arial;
    color: #000;
    border: none;
}

.custom-select {
    position: relative;
}

.select-selected {
    background-color: DodgerBlue;
}

.select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

input::placeholder {
    font-size: 15px !important;
    font-family: arial;
}

textarea::placeholder {
    font-size: 16px !important;
    font-family: arial;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    /* input: :-ms-reveal,
      input::-ms-clear; */
    display: none;
}

.reset_title h5 {
    font-family: Arial !important;
    font-size: 26px !important;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
}

.reset_btn .resCan {
    border-radius: 6px;
    padding: 5px 12px;
    font-weight: 500;
    font-family: Arial;
    color: #000;
    border: none;
}

.reset_btn .reset-btn {
    background-color: #FFF100;
    margin-top: 0px;
    font-family: Arial !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
}

.tutPdf {
    display: flex;
    gap: 10px;
}

.tutPdf a span {
    color: #FFF100;
    font-weight: 600;
    font-family: 'Arial';
    cursor: pointer;
}

.tutPdf img {
    cursor: pointer;
    width: 25px;
}

.previous_icon.rtn_details {
    padding: 20px 0px 0px;
}

button.btn.reset-btn.reset_btn:hover {
    background-color: yellow;
}

@media (max-width:576px) {

    button.btn.register-btn.reset-btn.reset_btn {
        padding: 5px 20px !important;
    }

    .Max_box a:before {
        position: absolute;
        background-color: #000;
    }
}

@media screen and (max-width: 425px) {
    button.btn.register-btn.reset-btn.reset_btn {
        padding: 5px 20px !important;
    }

    .Max_box a:before {
        position: absolute;
        background-color: #000;
    }
}

@media (min-width:767px) {
    .Max_box a:before {
        position: absolute;
        background-color: #000;
    }

}