div#exampleModal .modal-content {
    background-color: #000;
    border: 2px solid #fff100;
    color: #fff100 !important;
}

.forget_title {
    color: #fff100 !important;
    font-family: Arial;
}

.forget_btns {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 10px 0 0px;
}

.forget_btns .forget_submit {
    background-color: #fff100;
    color: #000;
    padding: 6px 27px;
    border: none;
    font-family: Arial;
}

.forget_btns .forget_can {
    color: #000;
    border: 1px solid #000;
    padding: 10px 27px;
    font-family: Arial;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.59px;
    text-align: left;

}

.modal-body input {
    width: 80%;
}

button.btn-close {
    color: #fff100 !important;
    background-color: #fff100;
    opacity: 1;
}

.modal-body small.errMsg {
    top: 0;
}

.loader {
    z-index: 99999;
}

.forget_head h3 {
    color: #ffff;
}

.forget_box {
    text-align: center;
    border: 1px solid #FFF100;
    margin-right: auto;
    margin-left: auto;
    /* width: 400px; */
    background-color: #000000;
    border-radius: 6px;
    padding: 50px 0;
    margin-top: 50px;
}

.forget_smal .errMsg {
    color: red;
}

.forget_foot {
    margin-top: 50px;
}

.toster h2 {
    padding: 10px;
    font-size: 16px;
    margin: 0;
    color: #fff;
}

.toster {
    position: fixed;
    right: 10px;
    z-index: 999;
    border-radius: 5px;
}

.toster.suc {
    background-color: green;
}

.forget_icon {
    padding: 0px 0px 40px;
}

.max_mob {
    display: none;
}

@media (max-width:767px) {
    .modal-body input {
        width: 100%;
    }

    .max_mob {
        display: block;
        width: 170px;
        margin: auto;
        height: auto !important;
    }

    .forNew {
        padding-top: 20px !important;
        color: #FFF100 !important;
        font-weight: 600;
        font-family: 'Arial';
        cursor: pointer;
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .forNew img {
        width: 25px;
    }

    .forget_foot .bottom_bar {
        bottom: -50px;
    }

    .pt-4.d-sm-block.d-md-none.forNew span {
        color: #FFF100;
    }

    .pt-4.d-sm-block.d-md-none.forNew a {
        text-decoration: none;
    }
}


@media (max-width:576px) {
    .max_mob {
        display: block;
        width: 170px;
        margin: auto;
        height: auto !important;
    }

    .forNew {
        padding-top: 20px !important;
        color: #FFF100 !important;
        font-weight: 600;
        font-family: 'Arial';
        cursor: pointer;
        display: flex !important;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .forget_foot .bottom_bar {
        bottom: -50px;
    }

    /* .forNew img {
        width: 25px;
    } */
}