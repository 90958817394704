/* .container {
    background-color: #FFF;
} */

.policyMain {
    background-color: #fff;
}

.privacy_head h1 {
    font-size: 28px;
    margin-top: 25px;
    margin-bottom: 10px;
    font-weight: 700;
}

.head_home a {
    text-decoration: none;
}

.firstPara p {
    min-height: 100%;
    font-family: Arial;
    color: #060808;
    line-height: 1.6;
    font-size: 15px;
    letter-spacing: 0.2px;
    font-weight: 400;
}

.minihead {
    margin-bottom: 20px;
    font-family: Arial;
}

.text_strong {
    margin-bottom: 20px;
}

.firstPara ul {
    margin-top: 15px;
}

.firstPara a {
    text-decoration: none;
}

.firstPara a:hover {
    color: #fff100;
}

.classUnderline u {
    min-height: 100%;
    font-family: Arial;
    color: #060808;
    line-height: 1.6;
    font-size: 15px;
    letter-spacing: 0.2px;
    font-weight: 400;
}

.classUnderline {
    padding: 20px 0;
}

.firstPara .tabText {
    text-indent: 3%;
}

.firstPara .italicText {
    font-style: italic;
    line-height: 1.6;
    font-size: 15px;
    letter-spacing: 0.2px;
    font-weight: 400;
}

.firstPara .firstAdd {
    min-height: 100%;
    font-family: Arial;
    color: #060808;
    line-height: 1.6;
    font-size: 15px;
    letter-spacing: 0.2px;
    font-weight: 400;
}

.textUnder_italic {
    text-decoration: underline;
    font-style: italic;
    min-height: 100%;
    font-family: Arial;
    color: #060808;
    line-height: 1.6;
    font-size: 15px;
    letter-spacing: 0.2
}

.head_home {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    padding: 0px 0;
}

.head_home p:after {
    border: solid #060808;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: relative;
    top: -1px;
    content: '';
    margin-left: 5px;
    margin-right: 5px;
}

.head_home p {
    color: #060808;
    text-decoration: none !important;
    font-size: 12px;
    display: block;
    font-family: Arial;

}

.head_home span {
    color: #060808;
    text-decoration: none;
    font-size: 12px;
    display: block;
    font-family: Arial;
}

/* .goTOp button{

} */
/* #scroll-top:before {
    font-size: 18px;
    color: #2c2c2c;
} */

#scroll-top {
    position: fixed;
    right: 20px;
    z-index: 999;
    bottom: 60px;
    display: inline-block;
    line-height: normal;
    height: auto;
    content: '';
    top: auto;
    font-size: 0px;
    background: #fff100;
    border: 1px solid rgba(103, 103, 103, 0.1490196078);
    padding: 4px;
    cursor: pointer;
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: "Glyphicons Halflings";
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.glyphicon-menu-up:before {
    content: "";
}

















