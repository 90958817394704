.terms_head h1 {
    font-size: 28px;
    margin: 25px 0px;
    font-family: Arial;
    font-weight: 700;
}

.firstPara p {
    min-height: 100%;
    font-family: Arial;
    color: #060808;
    line-height: 1.6;
    font-size: 15px;
    letter-spacing: 0.2px;
    font-weight: 400;
}

.strong_bt strong {
    margin-bottom: 15px;
    font-family: Arial;
}

.head_home p:after {
    border: solid #060808;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: relative;
    top: -1px;
    content: '';
    margin-left: 5px;
    margin-right: 5px;
}

.terms_cond {
    background-color: #fff;
    padding: 30px;
}

.strong_bott {
    margin-bottom: 15px;
}