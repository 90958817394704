/* body {
  background-color: #000;
} */
button:focus:not(:focus-visible) {
  outline: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.navbar-toggler-icon {

  background-image: url('../assets/hamburger.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.toaster h2 {
  padding: 10px;
  font-size: 16px;
  margin: 0;
  color: #fff;
}

.toaster {
  position: fixed;
  right: 30px;
  z-index: 999;
  border-radius: 5px;
}

.toaster.suc {
  background-color: green;
}

.toaster.fail {
  background-color: red;
}

.toaster.fail h2 {
  background-color: red;
  font-weight: 600 !important;
}

div#root>nav {
  display: none;
}

small.errMsg {
  color: red;
  position: relative;
  top: -5px;
}

.form-area {
  border: 1px solid #FFF100;
  border-radius: 5px;
  /* position: relative; */
  background-color: #000000;
  padding: 2.5rem 2.5rem !important;
}

img.max-loader {
  width: 60px;
}

div#mob-user-tutor {
  color: #fff;
}

div#mob-user-tutor img {
  margin-right: 3px;
}

.loader {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  bottom: 0;
  left: 0;
  background-color: #2c1a1a8c;
}

.form-area h5 {
  font-family: Arial;
  font-size: 23px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

::placeholder {
  color: #ADB5BD;
  font-family: Arial;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
}

.header nav ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header nav ul li a {
  font-family: Arial;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  text-decoration: none;
  letter-spacing: 0em;
  /* text-align: left; */
  display: flex;
  align-items: center;
  gap: 10px;
}

li.register-btn a {
  color: #000 !important;
  background-color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

form.form-area input {
  border-radius: 4px;
  margin: 4px 0px;
}

li.register-btn a {
  padding: 10px 20px;
  border-radius: 4px;
}

img.logo {
  height: 30px !important;
  width: auto;
}

.header nav ul li {
  padding: 0px 10px;
  margin: 0px 5px;
}

.mobilemenu-area ul li a {
  color: #fff;
  font-family: Arial;
  text-decoration: none;
}

.mobilemenu-area ul {
  list-style: none;
}

li.nut {
  padding: 20px 0px 0px 0px;
}

button.btn.register-btn {
  background-color: #FFF100;
  margin-top: 0px;
  font-family: Arial;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: center;

}


button.btn.register-btn img {
  margin-right: 5px;
}

::placeholder {
  color: #ADB5BD;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #ADB5BD;
}

.bg-black-bottom h1 {
  font-family: Arial;
  font-size: 27px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

img.max-maxlogo {
  width: 300px;
  height: 78px !important;
}

.bg-black-bottom p {
  font-family: Arial !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.42px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

.bg-black-bottom a {
  font-family: Arial !important;
}

.header nav ul {
  list-style: none;
}

.check_forget input[type=checkbox] {
  position: absolute;
  visibility: visible;
}

.check_forget .input_text p {
  margin-left: 20px;
}

/* Media Queries */
@media (max-width:767px) {
  .bg-black-bottom.row.text-center.pt-5 {
    flex-direction: column;
  }

  .login_content {
    margin-top: 50px;
  }

  .bg-black-bottom.row.text-center {
    margin-top: 50px;
  }

  .header nav ul {
    padding: 0px !important;
  }

  .header nav ul li {
    padding: 0px 5px;
  }

  .header nav ul li a {
    font-size: 11px;
  }

  nav.p-2 {
    padding: 0.5rem 0px !important;
    margin-top: 30px;
  }

  img.logo {
    width: 170px;
    margin: auto;
    height: auto !important;
  }

  .header {
    flex-direction: column;
  }

  .container-bg.p-5 {
    padding: 1rem 20px 3rem 20px !important;
  }

  form.form-area.p-5 {
    padding: 30px 10px !important;
    margin-top: 30px;
  }

  img.max-maxlogo {
    width: auto;
    height: auto !important;
  }

  button.btn.register-btn {
    width: 100%;
  }

  div#mob-user-tutor {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .toaster.suc {
    right: 13px;
    top: 120px;
  }

  .register_footer .bottom_bar {
    bottom: -225px;
  }
}

@media (max-width:576px) {
  .toaster.suc {
    right: 13px;
    top: 120px;
  }

  .register_footer .bottom_bar {
    bottom: -225px;
  }
}